import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueCookies from 'vue-cookies'
import VueYoutube from 'vue-youtube'
import "typeface-notokufiarabic/index.css"
import "@openfonts/tajawal_arabic/index.css"
Vue.use(VueCookies)
Vue.use(VueYoutube)
import AlertComponent from './components/Shared/Alert.vue'

Vue.component('app-alert', AlertComponent)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
