<template>
  <v-container>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </v-row>
    <v-row v-if="!loading" align="center" justify="center">
      <v-col cols="12" sm="6">
        <v-card tile>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">الاستاذ</div>
              <v-list-item-title class="headline mb-1">
                {{ lecturer.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ lecturer.email }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ lecturer.phone }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80" color="grey">
              <v-img :src="lecturer.image"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-text>
            <div class="text--primary">
              {{ lecturer.description }}
            </div>
          </v-card-text>
          <v-divider></v-divider>

          <v-expansion-panels>
            <v-expansion-panel
              :disabled="!lecturerGeneralCourses ? true : false"
            >
              <v-expansion-panel-header>
                <div class="font-weight-bold">المواد العامة</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-timeline dense>
                  <v-timeline-item
                    small
                    v-for="generalCourse in lecturerGeneralCourses"
                    :key="generalCourse.id"
                  >
                    <v-card class="elevation-2">
                      <v-card-title>
                        {{ generalCourse.name }}
                      </v-card-title>

                      <v-card-text>
                        <v-row align="center" class="mx-0">
                          <v-rating
                            :value="generalCourse.rating"
                            color="secondary"
                            dense
                            half-increments
                            readonly
                            size="14"
                          ></v-rating>

                          <div class="grey--text mr-4">
                            {{ generalCourse.rating }}
                          </div>
                        </v-row>
                        <div class="mt-4 subtitle-1">
                          ({{ generalCourse.no_of_lessons }}) محاظرة
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :disabled="!lecturerStudyCourses ? true : false">
              <v-expansion-panel-header>
                <div class="font-weight-bold">المواد الدراسية</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-timeline dense>
                  <v-timeline-item
                    small
                    v-for="studyCourse in lecturerStudyCourses"
                    :key="studyCourse.id"
                  >
                    <v-card class="elevation-2">
                      <v-card-title>
                        {{ studyCourse.name }}
                      </v-card-title>
                      <v-card-text>
                        <v-row align="center" class="mx-0">
                          <v-rating
                            :value="studyCourse.rating"
                            color="secondary"
                            dense
                            half-increments
                            readonly
                            size="14"
                          ></v-rating>

                          <div class="grey--text mr-4">
                            {{ studyCourse.rating }}
                          </div>
                        </v-row>
                        <div class="mt-4 subtitle-1">
                          ({{ studyCourse.no_of_lessons }}) محاظرة
                        </div>
                        <div class="subtitle-1">
                          المرحلة {{ studyCourse.stage }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-actions>
            <v-btn color="secondary" text @click="$router.go(-1)"> رجوع </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Lecturer",
  data: () => ({}),
  props: ["id"],
  mounted() {
    this.$store.dispatch("loadLecturer", this.id);
    this.$store.dispatch("loadLecturerGeneralCourses", this.id);
    this.$store.dispatch("loadLecturerStudyCourses", this.id);
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    lecturer() {
      return this.$store.getters.lecturer;
    },
    lecturerGeneralCourses() {
      return this.$store.getters.lecturerGeneralCourses;
    },
    lecturerStudyCourses() {
      return this.$store.getters.lecturerStudyCourses;
    },
  },
};
</script>

   

