
<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6">
        <v-card width="700">
          <v-card-title> {{ $route.query.info.title }}</v-card-title>
          <v-card-text>
            {{ $route.query.info.description }}
          </v-card-text>
          <v-card-text class="text-center my-5">
            <youtube :video-id="video_id" ref="youtube"></youtube>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              text
              :disabled="disabled"
              @click="storeToWatchLater()"
            >
              مشاهدة لاحقا
            </v-btn>
            <v-btn color="secondary" text @click="$router.go(-1)"> رجوع </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
        <v-row align="center" justify="center">
    <v-snackbar
      v-model="snackbar"
      color="success"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
 </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "Lesson",
  data: () => ({
    loadingWatchLater: false,
    disabled: false,
    snackbar: false,
      message: null,
  }),
  props: ["id", "video_id"],
   computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    storeToWatchLater() {
      this.loadingWatchLater = true;
      const token = this.user.access_token;
      axios
        .post(
          "https://edu-api.turathalanbiaa.com/api/watch-later-lessons/store",
          {
            lesson: this.id,
          },
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => response.data)
        .then((watchLater) => {
          this.loadingWatchLater = false;
          this.disabled = true;
          this.message=watchLater.message
          this.snackbar=true
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

   

