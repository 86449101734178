import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import AuthModule from "./AuthModule";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
  },
  state: {
    courses: [],
    course: {},
    lecturers:[],
    lecturer:{},
    lecturerGeneralCourses:[],
    lecturerStudyCourses:[],
    announcements:[],
    timetables:[],
    reviews:{},
    watchLaterLessons:[],
    loading: false,
    loadingPart:false,
    error: null,
  },
  getters: {
    courses(state) {
      return state.courses;
    },
    course(state) {
      return state.course;
    },
    lecturers(state) {
      return state.lecturers;
    },
    lecturer(state) {
      return state.lecturer;
    },
    
    lecturerGeneralCourses(state) {
      return state.lecturerGeneralCourses;
    },
    lecturerStudyCourses(state) {
      return state.lecturerStudyCourses;
    },
    announcements(state) {
      return state.announcements;
    },
    timetables(state) {
      return state.timetables;
    },
    reviews(state) {
      return state.reviews;
    },
    watchLaterLessons(state) {
      return state.watchLaterLessons;
    },
    loading(state) {
      return state.loading;
    },
    loadingPart(state) {
      return state.loadingPart;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    setCourses(state, courses) {
      state.courses = courses;
    },
    setCourse(state, course) {
      state.course = course;
    },
    setLecturers(state, lecturers) {
      state.lecturers = lecturers;
    },
    setLecturer(state, lecturer) {
      state.lecturer = lecturer;
    },
    setLecturerGeneralCourses(state, lecturerGeneralCourses) {
      state.lecturerGeneralCourses = lecturerGeneralCourses;
    },
    setLecturerStudyCourses(state, lecturerStudyCourses) {
      state.lecturerStudyCourses = lecturerStudyCourses;
    },
    setAnnouncements(state, announcements) {
      state.announcements = announcements;
    },
    setTimetables(state, timetables) {
      state.timetables = timetables;
    },
    setReviews(state, reviews) {
      state.reviews = reviews;
    },
    
    setWatchLaterLessons(state, watchLaterLessons) {
      state.watchLaterLessons = watchLaterLessons;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setLoadingPart(state, payload) {
      state.loadingPart = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
  actions: {
    //Courses
    loadGeneralCourses(context,payload) {
      context.commit("setLoading", true);
      const token=context.getters.user.access_token;
      axios
        .get(
          "https://edu-api.turathalanbiaa.com/api/general-courses?page="+ payload +"&no=8&q",
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )

        .then((response) => response.data)
        .then((courses) => {
          context.commit("setLoading", false);
          context.commit("setCourses", courses);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadStudyCourses(context,payload) {
      context.commit("setLoading", true);
      const token=context.getters.user.access_token;
      axios
        .get(
          "https://edu-api.turathalanbiaa.com/api/study-courses?page="+ payload +"&no=8&q",
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )

        .then((response) => response.data)
        .then((courses) => {
          context.commit("setLoading", false);
          context.commit("setCourses", courses);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadMyGeneralCourses(context) {
      context.commit("setLoading", true);
      const token=context.getters.user.access_token;
      axios
        .get(
          "https://edu-api.turathalanbiaa.com/api/my-general-courses",
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )

        .then((response) => response.data)
        .then((courses) => {
          context.commit("setLoading", false);
          context.commit("setCourses", courses);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadCourse(context,payload) {
      context.commit("setLoading", true);
      const token=context.getters.user.access_token;
      axios
        .get(
          payload.type==2? 'https://edu-api.turathalanbiaa.com/api/study-courses/'+ payload.id :' https://edu-api.turathalanbiaa.com/api/general-courses/'+ payload.id,
          
         

          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )

        .then((response) => response.data)
        .then((course) => {
          context.commit("setLoading", false);
          context.commit("setCourse", course.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //Lecturers
    loadLecturers(context,payload) {
      context.commit("setLoading", true);
      const token=context.getters.user.access_token;
      axios
        .get(
          "https://edu-api.turathalanbiaa.com/api/lecturers?page="+ payload +"&no=16&q",
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )

        .then((response) => response.data)
        .then((lecturers) => {
          context.commit("setLoading", false);
          context.commit("setLecturers", lecturers);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadLecturer(context,payload) {
      context.commit("setLoading", true);
      const token=context.getters.user.access_token;
      axios
        .get(
          "https://edu-api.turathalanbiaa.com/api/lecturers/"+ payload,
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )

        .then((response) => response.data)
        .then((lecturer) => {
          context.commit("setLoading", false);
          context.commit("setLecturer", lecturer.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadLecturerGeneralCourses(context,payload) {
      context.commit("setLoading", true);
      const token=context.getters.user.access_token;
      axios
        .get(
          "https://edu-api.turathalanbiaa.com/api/lecturers/" + payload + "/general-courses",
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )

        .then((response) => response.data)
        .then((lecturerGeneralCourses) => {
          context.commit("setLoading", false);
          context.commit("setLecturerGeneralCourses", lecturerGeneralCourses.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadLecturerStudyCourses(context,payload) {
      context.commit("setLoading", true);
      const token=context.getters.user.access_token;
      axios
        .get(
          "https://edu-api.turathalanbiaa.com/api/lecturers/" + payload + "/study-courses",
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )

        .then((response) => response.data)
        .then((lecturerStudyCourses) => {
          context.commit("setLoading", false);
          context.commit("setLecturerStudyCourses", lecturerStudyCourses.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
//Announcements
loadAnnouncements(context,payload) {
  context.commit("setLoading", true);
  const token=context.getters.user.access_token;
  axios
    .get(
      "https://edu-api.turathalanbiaa.com/api/announcements?page=" + payload + "&no=8&q",
      {
        headers: {
          "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
          Lang: "ar",
          Authorization: "Bearer " + token,
        },
      }
    )

    .then((response) => response.data)
    .then((announcements) => {
      context.commit("setLoading", false);
      context.commit("setAnnouncements", announcements);
    })
    .catch((error) => {
      console.log(error);
    });
},

//Timetables
loadTimetables(context,payload) {
  context.commit("setLoading", true);
  const token=context.getters.user.access_token;
  axios
    .get(
      payload=='t'? 'https://edu-api.turathalanbiaa.com/api/timetables/today-lessons':'https://edu-api.turathalanbiaa.com/api/timetables/missed-lessons',
      
     

      {
        headers: {
          "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
          Lang: "ar",
          Authorization: "Bearer " + token,
        },
      }
    )

    .then((response) => response.data)
    .then((timetables) => {
      context.commit("setLoading", false);
      context.commit("setTimetables", timetables.data);
    })
    .catch((error) => {
      console.log(error);
    });
},

//Reviews
loadReviews(context,payload) {
  const token=context.getters.user.access_token;
  axios
  .get(
    'https://edu-api.turathalanbiaa.com/api/reviews?course='+ payload.course +'&type='+ payload.type +'&page='+ payload.page +'&no=8',
    {
      headers: {
        "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
        Lang: "ar",
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((response) => response.data)
    .then((reviews) => {
      context.commit("setReviews", reviews.data);
    })
    .catch((error) => {
      console.log(error);
    });
},
//WatchLaterLessons
loadWatchLaterLessons(context) {
  const token=context.getters.user.access_token;
  axios
  .get(
    'https://edu-api.turathalanbiaa.com/api/watch-later-lessons',
    {
      headers: {
        "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
        Lang: "ar",
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((response) => response.data)
    .then((watchLaterLessons) => {
      context.commit("setWatchLaterLessons", watchLaterLessons.data);
    })
    .catch((error) => {
      console.log(error);
    });
},
    clearError ({commit}) {
      commit('clearError')
    },
  },
});
