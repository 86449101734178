<template>
  <v-container>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </v-row>
    <v-row v-if="!loading">
      <v-col
        v-for="lecturer in lecturers.data"
        :key="lecturer.id"
        cols="12"
        sm="3"
      >
        <v-card tile>
          <v-list-item>
            <v-list-item-content>
              <div class="overline mb-4">الاستاذ</div>
              <v-list-item-title class="headline mb-1">
                {{ lecturer.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar tile size="80">
              <v-img :src="lecturer.image"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
            <v-btn color="secondary" text :to="'/lecturer/' + lecturer.id">
              التفاصيل
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="!loading" class="text-center my-5">
      <v-pagination
        v-model="page"
        :length="lecturers.max_page"
        :total-visible="8"
        @input="loadLecturers()"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Lecturers",
  data: () => ({
    page: 1,
  }),

  mounted() {
    this.loadLecturers();
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    lecturers() {
      return this.$store.getters.lecturers;
    },
  },
  methods: {
    loadLecturers() {
      this.$store.dispatch("loadLecturers", this.page);
    },
  },
};
</script>

   

