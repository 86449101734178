import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const opts = {
    rtl:true,
    theme: {
        //dark:localStorage.getItem('theme') === 'dark',
        themes: {
            light: {
                primary: '#00897B',
                secondary: '#26A69A',
                accent: '#00ACC1',
               
            },
            dark: {
                 primary: '#263238',
                secondary: '#78909C',
                accent: '#82B1FF',
            
            },
        },
    },};
    export default new Vuetify(opts)