import axios from "axios";
import router from "@/router";
const AuthModule = {
  state: {
    user: null,
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    Register({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      
      axios
        .post(
          "https://edu-api.turathalanbiaa.com/api/register",
          {
            name: payload.name,
            email: payload.email,
            phone: payload.phone,
            gender: payload.gender,
            lang: payload.lang,
            password: payload.password,
            password_confirmation: payload.password_confirmation,
          },
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
            },
          }
        )

        .then((response) => response.data)
        .then((user) => {
          commit("setLoading", false);
          if (!user.data) {
            commit("setError", user);
          } else {
            commit("setUser", user.data);
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    Login({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      axios
        .post(
          "https://edu-api.turathalanbiaa.com/api/login",
          {
            username: payload.email,
            password: payload.password,
          },
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
            },
          }
        )

        .then((response) => response.data)
        .then((user) => {
          commit("setLoading", false);
          if (!user.data) {
            commit("setError", user);
          } else {
            commit("setUser", user.data);
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    autoSignIn({ commit }, payload) {
      commit("setUser", payload);
    },
    logout({ commit }) {
      window.$cookies.remove('user')
      commit("setUser", null);
      router.push("/login");
    },
  },
};

export default AuthModule;
