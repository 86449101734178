<template>
  <v-container>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </v-row>
    <v-row v-if="!loading" align="center" justify="center">
      <v-col cols="12" sm="6">
        <v-card tile>
          <v-img height="200" :src="course.image"></v-img>

          <v-card-title class="text-truncate">{{ course.name }}</v-card-title>
          <v-card-text>
            <div class="my-4 subtitle-1">
              ({{ course.no_of_lessons }}) محاظرة
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="font-weight-bold">المحاظر</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4">الاستاذ</div>
                    <v-list-item-title class="headline mb-1">
                      {{ course.lecturer.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ course.lecturer.email}}
                      </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ course.lecturer.phone}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-avatar tile size="80" color="grey">
                    <v-img :src="course.lecturer.image"></v-img>
                  </v-list-item-avatar>
                </v-list-item>
                <v-card-text>
                  {{ course.lecturer.description }}
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="font-weight-bold">المحاضرات</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-right">#</th>
                        <th class="text-right">النوع</th>
                        <th class="text-right">العنوان</th>
                        <th class="text-right">الوقت</th>
                        <th class="text-right">المشاهدات</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="lesson in course.lessons" :key="lesson.id">
                        <td>{{ lesson.order }}</td>
                        <td>
                          <v-btn
                            icon
                            color="red"
                            :to="{
                              path:
                                '/lesson/' + lesson.id + '/' + lesson.video_id,
                              query: { info: lesson },
                            }"
                          >
                            <v-icon> mdi-youtube</v-icon>
                          </v-btn>
                        </td>
                        <td>{{ lesson.title }}</td>
                        <td>
                          <v-icon small> mdi-clock</v-icon>
                          {{ lesson.video_length }}
                        </td>
                        <td>{{ lesson.seen }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="font-weight-bold">المراجعات</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="review in reviews" :key="review.id">
                  <v-list-item three-line>
                    <v-list-item-avatar>
                      <v-img :src="review.user.image"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="review.user.name"
                      ></v-list-item-title>
                      <v-list-item-title>
                        <v-rating
                          :value="review.rate"
                          color="secondary"
                          background-color="grey darken-1"
                          empty-icon="$ratingFull"
                          small
                        ></v-rating>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-html="review.created_at"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-alert
                    border="left"
                    text
                    colored-border
                    color="secondary"
                    icon="mdi-comment"
                    dense
                  >
                    {{ review.comment }}
                  </v-alert>
                </div>
                <v-divider></v-divider>

                <div class="text-center mb-2">
                  <strong>التقييم</strong>
                  <v-rating
                    v-model="rate"
                    color="secondary"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    hover
                    small
                  ></v-rating>
                </div>
                <v-text-field
                  hide-details
                  outlined
                  filled
                  dense
                  v-model="comment"
                  :prepend-inner-icon="comment ? 'mdi-send' : ' '"
                  clear-icon="mdi-close-circle"
                  clearable
                  label="اترك تعليق"
                  type="text"
                  @click:prepend-inner="sendComment"
                  @click:clear="clearComment"
                  :loading="loadingComment"
                  :disabled="disabledComment"
                ></v-text-field>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-actions>
            <v-btn
              v-if="type == 1 || type == 3"
              color="secondary"
              text
              :loading="loadingEnrollments"
              :disabled="(type == 1 && course.is_enrolled) || disabled"
              @click="enrollments()"
            >
              {{ type == 1 ? "اشتراك" : "الغاء الاشتراك" }}
            </v-btn>
            <v-btn color="secondary" text @click="$router.go(-1)"> رجوع </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-snackbar v-model="snackbar" color="success">
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> اغلاق </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "Course",
  data: () => ({
    page: 1,
    rate: null,
    comment: "",
    loadingComment: false,
    disabledComment: true,
    loadingEnrollments: false,
    snackbar: false,
    message: null,
    disabled: false,
  }),
  props: ["id", "type"],
  mounted() {
    this.$store.dispatch("loadCourse", { id: this.id, type: this.type });
    this.$store.dispatch("loadReviews", {
      course: this.id,
      type: this.type,
      page: 1,
    });
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    loading() {
      return this.$store.getters.loading;
    },
    loadingPart() {
      return this.$store.getters.loadingPart;
    },
    course() {
      return this.$store.getters.course;
    },
    reviews() {
      return this.$store.getters.reviews;
    },
  },
  watch: {
    rate(val) {
      if (!val) return;
      this.disabledComment = false;
    },
  },
  methods: {
    sendComment() {
      this.loadingComment = true;
      const token = this.user.access_token;
      axios
        .post(
          "https://edu-api.turathalanbiaa.com/api/reviews/create-or-update",
          {
            course: this.id,
            type: this.type,
            rate: this.rate,
            comment: this.comment,
          },
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )

        .then((response) => response.data)
        .then((reviews) => {
          this.loadingComment = false;
          this.clearComment();
          console.log(reviews);
        })
        .catch((error) => {
          this.loadingComment = false;
          this.$store.commit("setError", error);
        });
    },
    clearComment() {
      this.comment = "";
      this.rate = null;
      this.disabledComment = true;
      this.$store.dispatch("loadReviews", {
        course: this.id,
        type: this.type,
        page: 1,
      });
    },
    enrollments() {
      this.loadingEnrollments = true;
      const token = this.user.access_token;
      axios
        .post(
          "https://edu-api.turathalanbiaa.com/api/enrollments",
          {
            general_course: this.id,
            state: this.type == 1 ? 1 : 2,
          },
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )

        .then((response) => response.data)
        .then((enrollments) => {
          this.loadingEnrollments = false;
          this.message = enrollments.message;
          this.snackbar = true;
          this.disabled = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

   

