<template>
  <v-container>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </v-row>
   
      <v-row v-if="!loading">
      <v-col v-for="lesson in watchLaterLessons" :key="lesson.id"  cols="12" sm="3">
        <v-card >
          <v-card-text>
            <youtube  :video-id="lesson.video_id" ref="youtube"  width="350" height="200" ></youtube>
       </v-card-text>
          <v-card-text>
            <v-expansion-panels flat inset>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="font-weight-bold">الوصف</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ lesson.title }}
                      </v-list-item-title>
                       <v-list-item-subtitle>
                        {{ lesson.description}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> الاستاذ </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ lesson.lecturer.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> المادة </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ lesson.course.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
             <v-btn color="secondary"  text @click="deleteFromWatchLater(lesson.id)"> ازالة </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
     <v-row align="center" justify="center">
    <v-snackbar
      v-model="snackbar"
      color="success"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
   </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  name: "WatchLaterLessons",
  data: () => ({
    snackbar: false,
      message: null,
  }),
  mounted() {
    this.$store.dispatch("loadWatchLaterLessons");
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
     user() {
      return this.$store.getters.user;
    },
    watchLaterLessons() {
      return this.$store.getters.watchLaterLessons;
    },
  },

  methods: {
    deleteFromWatchLater(id) {
      this.loadingWatchLater = true;
      const token = this.user.access_token;
      axios
        .post(
          "https://edu-api.turathalanbiaa.com/api/watch-later-lessons/delete",
          {
            lesson: id,
          },
          {
            headers: {
              "App-Key": "base64:/RBhKZzvRM/aowkAQmBI/diaXAQV3cOxzv+jOXHMlmc=",
              Lang: "ar",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => response.data)
        .then((watchLater) => {
  
          this.message=watchLater.message
          this.snackbar=true
           this.$store.dispatch("loadWatchLaterLessons");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>