<template>
  <v-container>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </v-row>

    <v-row v-if="!loading">
      <v-col v-for="course in courses.data" :key="course.id" cols="12" sm="3">
        <v-card tile>
          <v-img height="200" :src="course.image"></v-img>

          <v-card-title class="text-truncate">{{ course.name }}</v-card-title>

          <v-card-text>
            <v-row align="center" class="mx-0">
              <v-rating
                :value="course.rating"
                color="secondary"
                dense
                half-increments
                readonly
                size="14"
              ></v-rating>

              <div class="grey--text mr-4">
                {{ course.rating }}
              </div>
            </v-row>
            <div class="my-4 subtitle-1">
              ({{ course.no_of_lessons }}) محاظرة
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="secondary"
              text
              :to="'/course/' + course.id + '/' + type"
            >
              التفاصيل
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="!loading && type != 3" class="text-center my-5">
      <v-pagination
        v-model="page"
        :length="courses.max_page"
        :total-visible="8"
        @input="loadCourses()"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Courses",
  data: () => ({
    page: 1,
  }),
  props: ["type"],
  mounted() {
    this.loadCourses();
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    courses() {
      return this.$store.getters.courses;
    },
  },
  watch: {
    type(newType) {
      this.page = 1;
      switch (newType) {
        case "1":
          this.$store.dispatch("loadGeneralCourses", this.page);
          break;
        case "2":
          this.$store.dispatch("loadStudyCourses", this.page);
          break;
        case "3":
          this.$store.dispatch("loadMyGeneralCourses");
          break;
        default:
          this.$store.dispatch("loadGeneralCourses", this.page);
      }
    },
  },
  methods: {
    loadCourses() {
      switch (this.type) {
        case "1":
          this.$store.dispatch("loadGeneralCourses", this.page);
          break;
        case "2":
          this.$store.dispatch("loadStudyCourses", this.page);
          break;
        case "3":
          this.$store.dispatch("loadMyGeneralCourses");
          break;
        default:
          this.$store.dispatch("loadGeneralCourses", this.page);
      }
    },
  },
};
</script>

   

