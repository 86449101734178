import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Timetables from '@/components/Timetables'
import Announcements from '@/components/Announcements'
import Lecturers from '@/components/Lecturers'
import Lecturer from '@/components/Lecturer'
import Courses from '@/components/Courses'
import Course from '@/components/Course'
import Lesson from '@/components/Lesson'
import WatchLaterLessons from '@/components/WatchLaterLessons'
import Register from '@/components/User/Register'
import Login from '@/components/User/Login'
import Profile from '@/components/User/Profile'
import AuthGuard from './auth-guard'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: AuthGuard
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/timetables/:type',
    name: 'Timetables',
    component: Timetables,
    props: true,
    beforeEnter: AuthGuard
  },
  {
    path: '/announcements',
    name: 'Announcements',
    component: Announcements,
    props: true,
    beforeEnter: AuthGuard
  },
  {
    path: '/lecturers',
    name: 'Lecturers',
    component: Lecturers,
    props: true,
    beforeEnter: AuthGuard
  },
  {
    path: '/lecturer/:id',
    name: 'Lecturer',
    component: Lecturer,
    props: true,
    beforeEnter: AuthGuard
  },
  {
    path: '/courses/:type',
    name: 'Courses',
    component: Courses,
    props: true,
    beforeEnter: AuthGuard
  },
  {
    path: '/course/:id/:type',
    name: 'Course',
    component: Course,
    props: true,
    beforeEnter: AuthGuard
  },
  {
    path: '/lesson/:id/:video_id',
    name: 'Lesson',
    component: Lesson,
    props: true,
    beforeEnter: AuthGuard
  },
  {
    path: '/watchLaterLessons',
    name: 'WatchLaterLessons',
    component:WatchLaterLessons,
    beforeEnter: AuthGuard
  },
  
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,

  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: AuthGuard
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
