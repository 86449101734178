<template>
  <v-container>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </v-row>
   
      <v-row v-if="!loading">
      <v-col v-for="timetable in timetables" :key="timetable.id"  cols="12" sm="3">
        <v-card >
          <v-card-text>
            <youtube  :video-id="timetable.video_id" ref="youtube"  width="350" height="200" ></youtube>
       </v-card-text>
          <v-card-text>
            <v-expansion-panels flat inset>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="font-weight-bold">الوصف</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ timetable.title }}
                      </v-list-item-title>
                       <v-list-item-subtitle>
                        {{ timetable.description}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> الاستاذ </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ timetable.lecturer.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> المادة </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ timetable.course.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" text @click="$router.go(-1)"> رجوع </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Timetables",
  data: () => ({}),
  props: ["type"],
  mounted() {
    this.$store.dispatch("loadTimetables", this.type);
  },
  watch: {
    type(newType) {
      this.$store.dispatch("loadTimetables", newType);
    },
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    timetables() {
      return this.$store.getters.timetables;
    },
  },
};
</script>