<template>
  <v-app id="inspire">
    <v-app-bar app flat clipped-right color="primary" dark>
      <v-app-bar-nav-icon v-if="userIsAuthenticated" @click="drawer = !drawer">
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <strong>معهد تراث الانبياء للدراسات الحوزوية</strong>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-for="link in toolbarLink" v-bind:key="link.icon">
        <v-btn text :key="link.title" :to="link.to">
          <v-icon left>{{ link.icon }}</v-icon>
          <strong class="hidden-xs-only">{{ link.title }}</strong>
        </v-btn>
      </v-toolbar-items>
      <v-badge
        v-if="userIsAuthenticated"
        color="red"
        dot
        offset-x="22"
        offset-y="22"
        bordered
        :value="true"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-bell</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/t">
              <v-list-item-title>اليوم اخر موعد للتسجيل</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item to="/c">
              <v-list-item-title
                >السلام عليكم امتحانات الفصل الاول غدا
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-badge>
      <v-btn v-if="userIsAuthenticated" text @click="logout()">
        <v-icon>mdi-logout</v-icon>
        <strong>تسجيل خروج</strong>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-if="userIsAuthenticated"
      v-model="drawer"
      :mini-variant.sync="mini"
      clipped
      app
      right
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img :src="user.image"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="title">
              {{ user.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list shaped>
        <v-list-item
          v-for="link in drawerLinks"
          :key="link.icon"
          link
          color="primary"
          :to="link.to"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group prepend-icon="mdi-timetable">
          <template v-slot:activator>
            <v-list-item-title>الجدول</v-list-item-title>
          </template>
          <v-list-item to="/timetables/t">
            <v-list-item-content>
              <v-list-item-title> محاضرات اليوم</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/timetables/m">
            <v-list-item-content>
              <v-list-item-title> المحاضرات الفائتة</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-book">
          <template v-slot:activator>
            <v-list-item-title>المواد</v-list-item-title>
          </template>
          <v-list-item to="/courses/1">
            <v-list-item-content>
              <v-list-item-title>مواد عامة</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/courses/2">
            <v-list-item-content>
              <v-list-item-title>مواد دراسية</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/courses/3">
            <v-list-item-content>
              <v-list-item-title>المواد الخاصة بي</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item color="primary" to="/watchLaterLessons">
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>المشاهدة لاحقا</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="primary" to="/profile">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>حسابي</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <v-footer dark padless clipped-right app>
      <v-card class="flex" flat tile>
        <v-card-title class="primary">
          <strong class="subheading">تواصل معنا على السوشال ميديا.</strong>
          <v-spacer></v-spacer>
          <v-btn v-for="icon in icons" :key="icon" class="mx-4" dark icon>
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-2 white--text text-center">
          <strong>معهدتراث الانبياء</strong> — {{ new Date().getFullYear() }}
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    mini: false,
  }),
  computed: {
    toolbarLink() {
      let items = [];
      if (!this.userIsAuthenticated) {
        items = [
          { icon: "mdi-face", title: "تسجيل", to: "/register" },
          { icon: "mdi-lock-open", title: "تسجيل دخول", to: "/login" },
        ];
      }
      return items;
    },
    drawerLinks() {
      let items = [];
      items = [
        { title: "الاعلانات", icon: "mdi-home-city", to: "/announcements" },
        { title: "المحاظرين", icon: "mdi-account-multiple", to: "/lecturers" },
      ];
      return items;
    },
    icons() {
      let icons = [
        "mdi-facebook",
        "mdi-twitter",
        "mdi-instagram",
        "mdi-telegram",
        "mdi-youtube",
      ];
      return icons;
    },
    userIsAuthenticated() {
      return (
        this.$store.getters.user !== null &&
        this.$store.getters.user !== undefined
      );
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>