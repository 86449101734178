<template>
  <v-container>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </v-row>
    <v-row v-if="!loading" align="center" justify="center">
      <v-col
        v-for="announcement in announcements.data"
        :key="announcement.id"
        cols="12"
       
      >
       <v-alert
      border="left" 
      text
      colored-border
      color="secondary"
       icon="mdi-message-alert"
      elevation="2"
    >
    <div class="title">
        {{announcement.title}}
      </div>
      <div> {{announcement.description}}</div>
     
    </v-alert>
      </v-col>
    </v-row>
  <v-row align="center" justify="center" v-if="!loading" class="text-center my-5">
      <v-pagination
        v-model="page"
        :length="announcements.max_page"
        :total-visible="8"
        @input="loadAnnouncements()"
      ></v-pagination>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Announcements",
  data: () => ({
    page: 1,
  }),

  mounted() {
   this.loadAnnouncements();
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    announcements() {
      return this.$store.getters.announcements;
    },
  },
  methods: {
    loadAnnouncements() {
      this.$store.dispatch("loadAnnouncements", this.page);
    },
  },
};
</script>

   

