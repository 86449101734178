<template>
  <v-container>
    <v-row row v-if="error" align="center" justify="center">
      <v-col sm="4" xs="12">
        <app-alert @dismissed="onDismissed" :text="error.message"></app-alert>
      </v-col>
    </v-row>
    <v-row  class="fill-height" align="center" justify="center">
      <v-col sm="4" xs="12" class="">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark tile>
            <v-toolbar-title>تسجيل دخول</v-toolbar-title>
              <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              top
              color="green"
            ></v-progress-linear>
          </v-toolbar>
          <v-spacer></v-spacer>
          <v-card-text>
            <form @submit.prevent="onLogin">
              <v-col xs="12">
                <v-text-field
                  name="email"
                  label="الايميل"
                  id="email"
                  v-model="email"
                  type="email"
                  required
                ></v-text-field>
                <v-text-field
                  name="password"
                  label="كلمة المرور"
                  id="password"
                  v-model="password"
                  type="password"
                  required
                ></v-text-field>
                <v-btn dark color="primary" type="submit" :loading="loading"
                  >دخول</v-btn
                >
              </v-col>
            </form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          this.$cookies.set('user',value)
          this.$router.push("/")
        }
      }
    },
  methods: {
    onLogin() {
      this.$store.dispatch("Login", {
        email: this.email,
        password: this.password,
      });
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
};
</script>
